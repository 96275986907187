import React from 'react';
import { Router } from '@reach/router';
import loadable from '@loadable/component';

import Page from '~layout/Page';

import RestrictedRoute from '~containers/restricted-route';
import { ACCESS_LEVELS } from '~utils/required-subscription-plan';

const AdminPage = loadable(() => import('~components/admin/AdminPage'));

const AdminIndex = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminIndex
});
const AdminPopups = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminPopups
});
const AdminUsers = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminUsers
});
const AdminBrokenSubscription = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminBrokenSubscription
});
const AdminUserEdit = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminUserEdit
});
const AdminUserAdd = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminUserAdd
});
const AdminUserBulkAdd = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminUserBulkAdd
});
const AdminComments = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminComments
});
const AdminVideoInformation = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminVideoInformation
});
const AdminVideoReport = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminVideoReport
});
const AdminStatistics = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminStatistics
});
const AdminCohorts = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminCohorts
});
const AdminUserPremiumCheck = loadable(() => import('~containers/admin/'), {
  resolveComponent: components => components.AdminUserPremiumCheck
});

const Admin = () => {
  const minAccessLevel = ACCESS_LEVELS.ADMIN;

  return (
    <Page hideBanner hideNavbar hideFooter>
      <AdminPage>
        <Router>
          <RestrictedRoute
            path="/admin"
            component={AdminIndex}
            minAccessLevel={minAccessLevel}
            default
          />
          <RestrictedRoute
            path="/admin/popups"
            component={AdminPopups}
            minAccessLevel={minAccessLevel}
          />
          <RestrictedRoute
            path="/admin/users"
            component={AdminUsers}
            minAccessLevel={minAccessLevel}
          />
          <RestrictedRoute
            path="/admin/users-edit/:id"
            component={AdminUserEdit}
            minAccessLevel={minAccessLevel}
          />
          <RestrictedRoute
            path="/admin/users-add"
            component={AdminUserAdd}
            minAccessLevel={minAccessLevel}
          />
          <RestrictedRoute
            path="/admin/users-add-bulk"
            component={AdminUserBulkAdd}
            minAccessLevel={minAccessLevel}
          />
          <RestrictedRoute
            path="/admin/comments"
            component={AdminComments}
            minAccessLevel={minAccessLevel}
          />
          <RestrictedRoute
            path="/admin/video-information"
            component={AdminVideoInformation}
            minAccessLevel={minAccessLevel}
          />
          <RestrictedRoute
            path="/admin/stats"
            component={AdminStatistics}
            minAccessLevel={minAccessLevel}
          />
          <RestrictedRoute
            path="/admin/video-report"
            component={AdminVideoReport}
            minAccessLevel={minAccessLevel}
          />
          <RestrictedRoute
            path="/admin/cohorts"
            component={AdminCohorts}
            minAccessLevel={minAccessLevel}
          />
          <RestrictedRoute
            path="/admin/users-check-premium"
            component={AdminUserPremiumCheck}
            minAccessLevel={minAccessLevel}
          />
          <RestrictedRoute
            path="/admin/broken-subscription"
            component={AdminBrokenSubscription}
            minAccessLevel={minAccessLevel}
          />
        </Router>
      </AdminPage>
    </Page>
  );
};

export default Admin;
