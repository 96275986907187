import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import authorizeUser from '~utils/authorization-helper';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import useConversionFramework from '~hooks/useConversionFramework';

const RestrictedRoute = props => {
  const { minAccessLevel, children, component: Component, isAccount, ...rest } = props;
  const { isMarketingSite } = useConversionFramework();
  const { session } = useUserContext();
  const { isUserStateLoading } = session;
  const { hasAccess, redirectPath, redirectProps } = authorizeUser(
    minAccessLevel,
    session,
    isAccount,
    isMarketingSite
  );

  if (!isUserStateLoading && !hasAccess) {
    navigate(`${redirectPath}`, { ...redirectProps });
  }

  return Component ? <Component {...rest} /> : <>{children}</>;
};

RestrictedRoute.propTypes = {
  minAccessLevel: PropTypes.number.isRequired,
  children: PropTypes.node,
  component: PropTypes.elementType,
  isAccount: PropTypes.bool
};

RestrictedRoute.defaultProps = {
  children: null,
  component: null,
  isAccount: null
};

export default RestrictedRoute;
